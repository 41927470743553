import React from "react"

const FinishJobsSvg = () => (
  <svg data-name="Layer 1" width="100%" viewBox="0 0 574.17079 411.08457">
    <path
      d="M870.11 364.922H555.916a16.695 16.695 0 00-16.68 16.68v50.92a16.695 16.695 0 0016.68 16.68H870.11a16.695 16.695 0 0016.68-16.68v-50.92a16.695 16.695 0 00-16.68-16.68z"
      transform="translate(-312.915 -244.458)"
      fill="#f2f2f2"
    />
    <path
      d="M438.915 655.542h-125a1 1 0 110-2h125a1 1 0 010 2z"
      transform="translate(-312.915 -244.458)"
      fill="#3f3d56"
    />
    <path
      d="M420.561 342.436a9.377 9.377 0 00-1.922 14.25l-9.087 19.405 10.445 8.399 12.449-27.585a9.428 9.428 0 00-11.885-14.469z"
      transform="translate(-312.915 -244.458)"
      fill="#ffb8b8"
    />
    <path
      d="M368.34 350.311a13.058 13.058 0 0117.582 3.942l18.595 27.842 6.456-14.231a4 4 0 014.835-2.166l9.743 3.043a4 4 0 012.596 5.102l-6.67 19.694a17.02 17.02 0 01-25.758 8.567 17.019 17.019 0 01-2.787-2.397l-27.403-29.28a13.058 13.058 0 012.81-20.116z"
      transform="translate(-312.915 -244.458)"
      fill="#ccc"
    />
    <path
      d="M428.492 321.543a1.882 1.882 0 011.836 1.928l-.16 6.463.323.03-.116 4.389-.317-.04-.575 23.153a3.265 3.265 0 01-2.922 3.166l-14.721 1.55a2.471 2.471 0 01-2.724-2.625l2.232-32.967a3.057 3.057 0 012.581-2.815l14.212-2.208a1.88 1.88 0 01.351-.024z"
      transform="translate(-312.915 -244.458)"
      fill="#3f3d56"
    />
    <path
      d="M417.099 324.974l7.471-1.16a.619.619 0 00.52-.545.7.7 0 01.59-.616l1.573-.245a1.485 1.485 0 011.713 1.503l-.809 33.568a1.696 1.696 0 01-1.545 1.648l-14.71 1.308a1.31 1.31 0 01-1.424-1.384l1.985-32.663a2.113 2.113 0 011.785-1.96l1.659-.257a1.058 1.058 0 001.192.803z"
      transform="translate(-312.915 -244.458)"
      fill="#fff"
    />
    <path
      fill="#ffb8b8"
      d="M69.522 398.946L80.954 398.945 86.391 354.849 69.518 354.851 69.522 398.946z"
    />
    <path
      d="M379.987 640.136h35.93v13.882h-22.048a13.882 13.882 0 01-13.882-13.882z"
      transform="rotate(179.995 241.5 524.841)"
      fill="#2f2e41"
    />
    <path
      fill="#ffb8b8"
      d="M34.386 398.946L45.818 398.945 51.255 354.849 34.382 354.851 34.386 398.946z"
    />
    <path
      d="M344.851 640.136h35.93v13.882h-22.048a13.882 13.882 0 01-13.882-13.882z"
      transform="rotate(179.995 206.364 524.841)"
      fill="#2f2e41"
    />
    <circle cx={67.86696} cy={66.30574} r={24.56103} fill="#ffb8b8" />
    <path
      d="M393.422 376.476a37.86 37.86 0 00-18.28-31.212c-6.345-3.74-13.29-5.198-18.816.328a38.354 38.354 0 00-7.836 11.761 50.505 50.505 0 00-.934 37.49l13.152 36.335 30.439 3.297a4 4 0 004.427-4.136z"
      transform="translate(-312.915 -244.458)"
      fill="#2f2e41"
    />
    <path
      d="M362.638 426.703s-22.742 8.49-16.796 44.439c5.335 32.258 1.095 139.33.169 160.973a3.996 3.996 0 003.664 4.154l13.275 1.106a4 4 0 004.309-3.553l6.856-62.974a1 1 0 011.99.024l5.109 60.596a4 4 0 004.112 3.662l11.15-.353a4 4 0 003.872-4.13l-6.568-199.745zM385.71 309.145c8.215 3.37 18.705-.566 22.677-8.507s.828-18.694-6.794-23.246-18.581-2.22-23.689 5.043c-4.154-7.484-15.037-9.393-22.51-5.216s-11.543 12.924-12.025 21.47 2.115 16.967 5.285 24.918c5.127 12.863 21.633 19.789 34.403 14.436-5.846-8.707-4.692-21.45 2.654-28.898z"
      transform="translate(-312.915 -244.458)"
      fill="#2f2e41"
    />
    <path
      data-name="Path 395"
      d="M418.812 345.414a1.008 1.008 0 01-.606-.201l-.011-.009-2.284-1.747a1.015 1.015 0 011.235-1.61l1.48 1.134 3.495-4.56a1.014 1.014 0 011.422-.188l-.022.03.023-.03a1.016 1.016 0 01.187 1.423l-4.11 5.361a1.015 1.015 0 01-.808.396z"
      transform="translate(-312.915 -244.458)"
      fill="#991a5f"
    />
    <path
      d="M870.405 244.458H556.212a16.695 16.695 0 00-16.68 16.68v50.919a16.695 16.695 0 0016.68 16.68h314.193a16.695 16.695 0 0016.68-16.68v-50.919a16.695 16.695 0 00-16.68-16.68zm14.925 67.599a14.946 14.946 0 01-14.925 14.924H556.212a14.946 14.946 0 01-14.925-14.924v-50.919a14.946 14.946 0 0114.924-14.924h314.194a14.946 14.946 0 0114.925 14.924z"
      transform="translate(-312.915 -244.458)"
      fill="#3f3d56"
    />
    <path
      d="M620.15 315.63a29.032 29.032 0 1129.032-29.033 29.065 29.065 0 01-29.032 29.033z"
      transform="translate(-312.915 -244.458)"
      fill="#991a5f"
    />
    <path
      d="M827.536 310.301H676.634a7.023 7.023 0 110-14.047h150.902a7.023 7.023 0 010 14.047zM726.576 280.452h-49.942a7.023 7.023 0 110-14.047h49.942a7.023 7.023 0 010 14.047z"
      transform="translate(-312.915 -244.458)"
      fill="#ccc"
    />
    <path
      data-name="Path 395"
      d="M618.668 296.81a3.32 3.32 0 01-1.998-.664l-.036-.027-7.525-5.757a3.344 3.344 0 114.07-5.307l4.873 3.737 11.518-15.025a3.343 3.343 0 014.686-.62l.001.001-.071.1.073-.1a3.347 3.347 0 01.618 4.688L621.33 295.5a3.345 3.345 0 01-2.66 1.305z"
      transform="translate(-312.915 -244.458)"
      fill="#fff"
    />
    <path
      d="M735.405 381.458H421.212a16.695 16.695 0 00-16.68 16.68v50.919a16.695 16.695 0 0016.68 16.68h314.193a16.695 16.695 0 0016.68-16.68v-50.919a16.695 16.695 0 00-16.68-16.68z"
      transform="translate(-312.915 -244.458)"
      fill="#fff"
    />
    <path
      d="M735.405 381.458H421.212a16.695 16.695 0 00-16.68 16.68v50.919a16.695 16.695 0 0016.68 16.68h314.193a16.695 16.695 0 0016.68-16.68v-50.919a16.695 16.695 0 00-16.68-16.68zm14.925 67.599a14.946 14.946 0 01-14.925 14.924H421.212a14.946 14.946 0 01-14.925-14.924v-50.919a14.946 14.946 0 0114.924-14.924h314.194a14.946 14.946 0 0114.925 14.924z"
      transform="translate(-312.915 -244.458)"
      fill="#3f3d56"
    />
    <path
      d="M485.15 452.63a29.032 29.032 0 1129.032-29.033 29.065 29.065 0 01-29.032 29.033z"
      transform="translate(-312.915 -244.458)"
      fill="#991a5f"
    />
    <path
      d="M692.536 447.301H541.634a7.023 7.023 0 110-14.047h150.902a7.023 7.023 0 010 14.047zM591.576 417.452h-49.942a7.023 7.023 0 110-14.047h49.942a7.023 7.023 0 010 14.047z"
      transform="translate(-312.915 -244.458)"
      fill="#ccc"
    />
    <path
      data-name="Path 395"
      d="M483.668 433.81a3.32 3.32 0 01-1.998-.664l-.036-.027-7.525-5.757a3.344 3.344 0 114.07-5.307l4.873 3.737 11.518-15.025a3.343 3.343 0 014.686-.62l.001.001-.071.1.073-.1a3.347 3.347 0 01.618 4.688L486.33 432.5a3.345 3.345 0 01-2.66 1.305z"
      transform="translate(-312.915 -244.458)"
      fill="#fff"
    />
    <path
      d="M411.023 392.944a9.377 9.377 0 00-12.796 6.557l-21.12 3.618-.932 13.371 29.754-5.528a9.428 9.428 0 005.094-18.018z"
      transform="translate(-312.915 -244.458)"
      fill="#ffb8b8"
    />
    <path
      d="M374.743 354.565a13.058 13.058 0 016.803 16.686l-12.243 31.163 15.37-2.825a4 4 0 014.538 2.733l3.064 9.737a4 4 0 01-2.706 5.044l-19.977 5.768a17.02 17.02 0 01-21.74-16.257 17.019 17.019 0 01.377-3.657l8.394-39.214a13.058 13.058 0 0118.12-9.178z"
      transform="translate(-312.915 -244.458)"
      fill="#ccc"
    />
  </svg>
)

export default FinishJobsSvg
