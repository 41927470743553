import React from "react"
import tw from "twin.macro"

const Wrapper = tw.div`w-full`

const PoolsSvg = () => (
  <Wrapper>
    <svg width="100%" viewBox="0 0 1110 830">
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0.0043144361%" id="a">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="-2.2033937e-15%" id="b">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="99.9956856%" x2="50%" y2="0%" id="c">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M926.25 410.58c-46.177 12.377-58.007 17.065-86.67 41.08-28.663 24.015-17.641 34.683-64.35 62.71-46.709 28.027-55.088 20.62-93.09 31.76s-46.674-.348-79.24 35.402c-32.566 35.75-23.654 26.467-29.18 83.618-5.526 57.15-16.338 78.87 13.57 116.166 29.908 37.296 64.814 40.88 114.43 43.453 49.616 2.574 56.117-5.409 100.65-32.299 44.533-26.89 43.064-52.479 72.37-72.175 29.306-19.696 13.784-.049 72.106-24.925 58.321-24.876 78.574-19.543 113.764-54.355s35.211-41.662 34.57-92.385c-.64-50.723-17.697-75.485-37.02-101.55-19.323-26.065-35.252-28.18-65.32-36.5-30.068-8.32-20.413-12.377-66.59 0z"
          fillOpacity={0.767327155}
          fill="#FFE6CF"
        />
        <path
          d="M570.222 28.19C613.504-.703 647.72.505 647.72.505s60.699 4.343 100.6 27.685c39.901 23.342 11.204 40.436 59.09 65.734 47.886 25.297 69.355 7.018 118.91 28.301 49.555 21.283 61.544 12.22 85.422 59.455 23.878 47.234 4.649 74.397 0 109.524-4.648 35.127 2.935 39.005-23.622 68.994-26.557 29.99-43.89 37.261-79.859 47.862-35.968 10.601-45.54 3.077-72.721 21.52-27.18 18.443-6.096 17.805-28.13 46.91-22.034 29.105-29.485 31.062-69.845 48.03s-46.54 20.002-91.948 19.993c-45.409-.015-52.663-12.9-90.757-20.043-38.094-7.143-30.473-9.787-77.19-11.45-46.717-1.663-51.483 10.333-94.18 5.35-42.697-4.983-50.463 2.1-81.67-25.874s-26.764-36.662-31.609-75.666c-4.845-39.004-1.351-43.562 12.339-79.455 13.69-35.893.028-43.467 44.062-68.415 44.034-24.948 76.341 2.25 118.788-23.848s34.9-44.352 51.154-80.637c10.836-24.19 17.122-49.405 18.856-75.645 7.687-21.164 25.957-41.378 54.812-60.64z"
          fill="#D0EAE3"
        />
        <path
          d="M31.647 229.05c0-19.862-.864-55.132 50.576-89.92 51.44-34.788 85.567-16.905 122.698-16.905s38.587.478 113.157 42.25 41.412 27.753 100.502 54.195c59.09 26.442 99.28 46.95 114.7 90.84 15.42 43.89-5.188 109.801 0 203.51 5.188 93.709 64.191 104.706 21.58 186.28-42.611 81.574-96.961 94.115-171.37 100.476-74.409 6.36-93.677-45.019-151.09-72.911-57.413-27.892-88.923-47.155-114.7-56.215-25.777-9.06-44.746-23.757-76.236-65.83C9.974 562.747.154 562.876.154 513.6c0-49.276 24.41-55.711 31.493-96.77 7.082-41.059 0-17.196 0-85.814V229.05z"
          fillOpacity={0.102912956}
          fill="#991A5F"
        />
        <g transform="translate(53.83 156.2)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
          <path
            d="M116.21 229.02a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
            fill="#991A5F"
          />
          <circle fill="#333" cx={115.02} cy={112.76} r={50.29} />
          <path
            d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
            fill="#BE7C5E"
          />
          <path
            d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#BE7C5E" cx={115.02} cy={123.68} r={50.29} />
          <path
            d="M84.55 102.94c.59-2.17 1.33-4.56 3.28-5.69 1.54-.9 3.45-.76 5.23-.67 13.9.76 27.68-2.41 41.2-5.72 7.73-1.89 16-4.19 21-10.42 5-6.23-1.52-25.81-9.13-28.12-5-1.53-10.8 1.32-15.54-.92-2.11-1-3.66-2.87-5.48-4.33-3.73-3-8.52-4.21-13.16-5.35-5.42-1.34-11.28-2.65-16.43-.5-4 1.66-6.92 5.17-10.71 7.25-7.21 4-16 2.14-24.26 2.12-2.79 0-6.12.56-7.23 3.12-.75 1.74-.15 3.72-.07 5.61.24 6-4.71 10.67-8.86 15-4.15 4.33-8.07 10.51-5.45 15.89 1 2.05 2.83 3.6 3.87 5.63 1.79 3.52.89 7.77 1.54 11.66a29.34 29.34 0 003.27 8.4c3.08 6 18.55 38.3 25.66 30.09 3.44-4 4-15.56 5.39-20.76a1781.03 1781.03 0 015.88-22.29z"
            fill="#333"
          />
          <ellipse fill="#BE7C5E" cx={79.24} cy={121.85} rx={4.68} ry={8.77} />
        </g>
        <g transform="translate(552.19 284.43)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={116.11} cy={115.15} r={113.44} />
          <path
            d="M116.21 229.04a112.911 112.911 0 0062.32-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
            fill="#991A5F"
          />
          <circle fill="#F55F44" cx={115.01} cy={112.76} r={54.76} />
          <path
            d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39a3.35 3.35 0 013.35 3.35v24.72a17.54 17.54 0 01-17.54 17.54 17.54 17.54 0 01-17.54-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35h-.01z"
            fill="#FDA57D"
          />
          <path
            d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDA57D" cx={115.01} cy={123.68} r={50.29} />
          <circle fill="#F55F44" cx={114.94} cy={57.06} r={20.24} />
          <circle fill="#F55F44" cx={114.94} cy={37.13} r={13.29} />
          <path
            d="M106.16 68.16c-9.864-4.425-14.51-15.82-10.55-25.88a20.243 20.243 0 1036.9 16.55c-4.88 9.65-16.485 13.758-26.35 9.33z"
            fill="#F55F44"
          />
          <path
            d="M79.2 77.94s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.94z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z"
            fill="#F55F44"
          />
          <path
            d="M95.53 61.64a249.85 249.85 0 0139.18 0s-28.94 2.69-39.18 0z"
            fill="#333"
          />
          <path
            d="M117.98 23.03a13.32 13.32 0 00-3 .23h.77a13.28 13.28 0 012 26.33c7.334.066 13.334-5.826 13.4-13.16.066-7.334-5.826-13.334-13.16-13.4h-.01z"
            fill="#F55F44"
          />
          <ellipse fill="#FDA57D" cx={64.71} cy={123.63} rx={4.68} ry={8.77} />
          <ellipse fill="#FDA57D" cx={165.3} cy={123.63} rx={4.68} ry={8.77} />
          <path
            fill="#F55F44"
            d="M76.03 78.57L85.84 73.51 88.05 81.62 82.04 85.71z"
          />
        </g>
        <g transform="translate(286.08 272.51)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#b)"
          />
          <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
          <path
            d="M71.64 116.27s-12.86 63.36-19.88 59.85c0 0 67.7 58.48 127.48 0 0 0-10.53-44.65-25.73-59.85H71.64z"
            fill="#333"
          />
          <path
            d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
            fill="#991A5F"
          />
          <circle fill="#333" cx={115.01} cy={112.76} r={50.29} />
          <path
            d="M97.34 158.37h35.09v28.07c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-28.07h-.04z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-24.73a3.35 3.35 0 013.31-3.34z"
            fill="#BE7C5E"
          />
          <path
            d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#BE7C5E" cx={115.01} cy={123.67} r={50.29} />
          <path
            d="M66.94 104.57h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
            fill="#333"
          />
          <ellipse fill="#BE7C5E" cx={65.76} cy={121.53} rx={4.68} ry={8.77} />
          <ellipse fill="#BE7C5E" cx={164} cy={121.53} rx={4.68} ry={8.77} />
          <path
            d="M66.94 105.87h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
            fill="#000"
            opacity={0.1}
          />
        </g>
        <g transform="translate(286.08 272.51)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#b)"
          />
          <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
          <path
            d="M71.64 116.27s-12.86 63.36-19.88 59.85c0 0 67.7 58.48 127.48 0 0 0-10.53-44.65-25.73-59.85H71.64z"
            fill="#333"
          />
          <path
            d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
            fill="#991A5F"
          />
          <circle fill="#333" cx={115.01} cy={112.76} r={50.29} />
          <path
            d="M97.34 158.37h35.09v28.07c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-28.07h-.04z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-24.73a3.35 3.35 0 013.31-3.34z"
            fill="#BE7C5E"
          />
          <path
            d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#BE7C5E" cx={115.01} cy={123.67} r={50.29} />
          <path
            d="M66.94 104.57h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
            fill="#333"
          />
          <ellipse fill="#BE7C5E" cx={65.76} cy={121.53} rx={4.68} ry={8.77} />
          <ellipse fill="#BE7C5E" cx={164} cy={121.53} rx={4.68} ry={8.77} />
          <path
            d="M66.94 105.87h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
            fill="#000"
            opacity={0.1}
          />
        </g>
        <g transform="translate(756.34 142)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#c)"
          />
          <circle fill="#F5F5F5" cx={116.11} cy={115.14} r={113.44} />
          <path
            d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.19-22.85-20.09-24.16-20.09-24.16H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.57z"
            fill="#991A5F"
          />
          <circle fill="#72351C" cx={115.02} cy={112.75} r={54.76} />
          <path
            d="M97.34 158.36h35.09v28.07A17.54 17.54 0 01114.89 204c-9.687 0-17.54-7.853-17.54-17.54v-28.1h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.66 157.06h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35h-.01z"
            fill="#FDA57D"
          />
          <path
            d="M97.38 171.61a50.5 50.5 0 0035.09.1v-4.31H97.38v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDA57D" cx={115.02} cy={123.67} r={50.29} />
          <path
            d="M79.2 77.93s21.19 43 81 18L146.28 74.1l-24.67-8.86L79.2 77.93z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z"
            fill="#72351C"
          />
          <path
            d="M79.01 74.39a29.05 29.05 0 017.2-11.71c9.89-9.78 26.11-11.84 34.43-23a6.51 6.51 0 01-3 9.18c8-.05 17.21-.75 21.71-7.31a14.91 14.91 0 01-2.51 15.74c7.06.32 14.6 5.11 15.06 12.17.3 4.7-2.64 9.15-6.51 11.85-3.87 2.7-8.53 3.91-13.13 4.91-13.42 3-62.01 15.41-53.25-11.83zM165.31 124.11h-1.33l-25.94 23.11c-25-11.7-43.34 0-43.34 0l-27.2-22.14-2.74.34c.772 27.464 23.443 49.225 50.915 48.873 27.473-.352 49.578-22.688 49.645-50.163l-.01-.02zm-50.36 31.95c-9.78 0-17.71-2-17.71-4.43 0-2.43 7.93-4.43 17.71-4.43 9.78 0 17.71 2 17.71 4.43 0 2.43-7.93 4.43-17.72 4.43h.01z"
            fill="#72351C"
          />
          <ellipse fill="#FDA57D" cx={64.71} cy={123.62} rx={4.68} ry={8.77} />
          <ellipse fill="#FDA57D" cx={165.3} cy={123.62} rx={4.68} ry={8.77} />
        </g>
        <g transform="translate(53 400.84)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={116.11} cy={115.15} r={113.44} />
          <path
            d="M116.21 229.04a112.912 112.912 0 0062.35-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.72s-15 1.23-19.7 22.25a112.922 112.922 0 0065.19 20.56z"
            fill="#991A5F"
          />
          <circle fill="#333" cx={115.01} cy={112.76} r={54.76} />
          <path
            d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.54-17.54v-28.07h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.74c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-24.75c0-1.85 1.5-3.35 3.35-3.35v.01z"
            fill="#FDB797"
          />
          <path
            d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.34H97.41v4.24z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDB797" cx={115.01} cy={123.68} r={50.29} />
          <path
            d="M79.2 77.93s21.19 43 81 18L146.28 74.1l-24.67-8.86L79.2 77.93z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z"
            fill="#333"
          />
          <path
            d="M79.01 74.38a29.05 29.05 0 017.2-11.71c9.89-9.78 26.11-11.84 34.43-23a6.51 6.51 0 01-3 9.18c8-.05 17.21-.75 21.71-7.31a14.91 14.91 0 01-2.51 15.74c7.06.32 14.6 5.11 15.06 12.17.3 4.7-2.64 9.15-6.51 11.85-3.87 2.7-8.54 3.93-13.14 4.95-13.42 2.98-62 15.39-53.24-11.87z"
            fill="#333"
          />
          <ellipse fill="#FDB797" cx={64.7} cy={123.63} rx={4.68} ry={8.77} />
          <ellipse fill="#FDB797" cx={165.29} cy={123.63} rx={4.68} ry={8.77} />
        </g>
        <g transform="translate(596.66 560.69)">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
            fillRule="nonzero"
          />
          <circle
            fill="#F5F5F5"
            fillRule="nonzero"
            cx={115.89}
            cy={115.26}
            r={113.44}
          />
          <path
            d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
            fill="#991A5F"
            fillRule="nonzero"
          />
          <path
            d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-28.07z"
            fill="#000"
            fillRule="nonzero"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35z"
            fill="#FDB797"
            fillRule="nonzero"
          />
          <path
            d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z"
            fill="#000"
            fillRule="nonzero"
            opacity={0.1}
          />
          <circle
            fill="#FDB797"
            fillRule="nonzero"
            cx={115.01}
            cy={123.68}
            r={50.29}
          />
          <ellipse
            fill="#FDB797"
            fillRule="nonzero"
            cx={65.76}
            cy={121.53}
            rx={4.68}
            ry={8.77}
          />
          <ellipse
            fill="#FDB797"
            fillRule="nonzero"
            cx={164}
            cy={121.53}
            rx={4.68}
            ry={8.77}
          />
          <rect
            stroke="#333"
            strokeWidth={2}
            x={84.37}
            y={123.92}
            width={21.51}
            height={10.76}
            rx={4.35}
          />
          <rect
            stroke="#333"
            strokeWidth={2}
            transform="rotate(180 134.905 129.29)"
            x={124.15}
            y={123.91}
            width={21.51}
            height={10.76}
            rx={4.35}
          />
          <path
            d="M105.06 124.96s6.82-3.7 19.5 0"
            stroke="#333"
            strokeWidth={2}
          />
          <path
            d="M149.78 87.23l12.34 28.67s15.09-19.02-12.34-28.67z"
            fill="#858585"
            fillRule="nonzero"
          />
          <path
            stroke="#333"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.66 129.3L164.08 112.85"
          />
          <path
            d="M80.26 87.23L67.92 115.9S52.83 96.88 80.26 87.23z"
            fill="#858585"
            fillRule="nonzero"
          />
          <path
            stroke="#333"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M84.37 129.3L65.7 112.85"
          />
        </g>
        <g transform="translate(530.83 26.37)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
          <path
            d="M178.64 125.46c0 41.78-27.26 88.29-60.89 88.29-33.63 0-60.86-46.51-60.86-88.29 0-41.78 27.26-63 60.89-63 33.63 0 60.86 21.23 60.86 63z"
            fill="#FDD835"
          />
          <path
            d="M116.21 229.05a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
            fill="#991A5F"
          />
          <path
            d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
            fill="#FDB797"
          />
          <path
            d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDB797" cx={115.02} cy={123.68} r={50.29} />
          <ellipse fill="#FDB797" cx={65.77} cy={121.53} rx={4.68} ry={8.77} />
          <ellipse fill="#FDB797" cx={164.01} cy={121.53} rx={4.68} ry={8.77} />
          <path
            d="M70.46 96.4s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M70.46 95.77s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
            fill="#FDD835"
          />
        </g>
        <g transform="translate(828.83 433.37)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
          <path
            d="M178.64 125.46c0 41.78-27.26 88.29-60.89 88.29-33.63 0-60.86-46.51-60.86-88.29 0-41.78 27.26-63 60.89-63 33.63 0 60.86 21.23 60.86 63z"
            fill="#72351C"
          />
          <path
            d="M116.21 229.05a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
            fill="#991A5F"
          />
          <path
            d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
            fill="#FDB797"
          />
          <path
            d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDB797" cx={115.02} cy={123.68} r={50.29} />
          <ellipse fill="#FDB797" cx={65.77} cy={121.53} rx={4.68} ry={8.77} />
          <ellipse fill="#FDB797" cx={164.01} cy={121.53} rx={4.68} ry={8.77} />
          <path
            d="M70.46 96.4s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M70.46 95.77s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
            fill="#72351C"
          />
        </g>
        <g transform="translate(286.14 531.66)" fillRule="nonzero">
          <circle
            cx={115.89}
            cy={115.89}
            r={115.89}
            opacity={0.5}
            fill="url(#a)"
          />
          <circle fill="#F5F5F5" cx={116.12} cy={115.15} r={113.44} />
          <path
            d="M178.53 126.33c0 37.74-27.11 56.31-62.32 56.31s-65.16-18.57-65.16-56.31S79.59 58 114.79 58s63.74 30.56 63.74 68.33z"
            fill="#333"
          />
          <path
            d="M116.21 229.04a112.912 112.912 0 0062.37-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
            fill="#991A5F"
          />
          <path
            d="M97.35 158.34h35.09v28.07a17.54 17.54 0 01-17.58 17.57 17.54 17.54 0 01-17.54-17.54v-28.1h.03z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72a17.54 17.54 0 01-17.57 17.54 17.54 17.54 0 01-17.54-17.54v-24.73a3.35 3.35 0 013.35-3.35l.02.01z"
            fill="#FDA57D"
          />
          <path
            d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z"
            fill="#000"
            opacity={0.1}
          />
          <circle fill="#FDA57D" cx={115.02} cy={123.67} r={50.29} />
          <path
            d="M57.06 126.96s31.32-3.8 55-34.16c0 0 31.32 34.48 53.14 30.68 0 0 12.65-13.29 0-26.57-12.65-13.28-22.46-30.05-39.22-27.84-16.76 2.21-37.33 3.16-46.18 11.07-8.85 7.91-22.74 46.82-22.74 46.82z"
            fill="#000"
            opacity={0.1}
          />
          <path
            d="M57.06 126.33s31.32-3.8 55-34.16c0 0 31.32 34.48 53.14 30.68 0 0 12.65-13.29 0-26.57-12.65-13.28-22.46-30.05-39.22-27.84-16.76 2.21-37.29 3.12-46.14 11.12-8.85 8-22.78 46.77-22.78 46.77z"
            fill="#333"
          />
          <ellipse fill="#FDA57D" cx={64.71} cy={123.63} rx={4.68} ry={8.77} />
          <ellipse fill="#FDA57D" cx={165.3} cy={123.63} rx={4.68} ry={8.77} />
        </g>
      </g>
    </svg>
  </Wrapper>
)

export default PoolsSvg
