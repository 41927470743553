/* eslint-disable react/function-component-definition */
import { TextField } from "@mui/material";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import tw, { styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import { navigate } from "gatsby";
import { node, string } from "prop-types";

import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimButton,
  LoadingDialog,
  TilePrim,
} from "../components/util-components";
import { PageTitleContext } from "../utils/pagetitle-provider";
import JimHelmet from "../components/JimHelmet";
import { GET_USER_ROLES } from "../utils/queries";
import { UserRolesContext } from "../utils/userroles-provider";
import PoolsSvg from "../images/PoolsSvg";
import AvailabilitiesSvg from "../images/AvailabilitiesSvg";
import FinishJobsSvg from "../images/FinishJobsSvg";

const CREATE_FACILITY = gql`
  mutation CreateFacility($name: String!, $address: AddressInput) {
    createFacility(name: $name, address: $address) {
      id
      name
      address {
        street
        number
        zip
        city
      }
    }
  }
`;

const TextFieldWithWidth = styled(TextField)(({ width }) => [
  `width: ${width}%;`,
]);

const textFieldLib = {
  street: { width: 70, label: "Straße" },
  number: { width: 30, label: "Nr." },
  zip: { width: 40, label: "PLZ" },
  city: { width: 60, label: "Ort" },
};

const FeatureTile = ({ svg, title, descr }) => (
  <div tw="flex items-center bg-white shadow-md p-4 text-base rounded-md">
    <div tw="w-1/3 pr-4">{svg}</div>
    <div tw="w-2/3">
      <div tw="text-lg tracking-wider text-prim-500">{title}</div>
      <div>{descr}</div>
    </div>
  </div>
);
FeatureTile.propTypes = {
  svg: node.isRequired,
  title: string.isRequired,
  descr: string.isRequired,
};

const CreateFacility = () => {
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Einrichtung erstellen"), []);

  const [loading, setLoading] = useState(false);

  const [createFacility, { data, error }] = useMutation(CREATE_FACILITY);
  const [getUserData] = useLazyQuery(GET_USER_ROLES);

  const [formVars, setFormVars] = useState({
    name: "",
    street: "",
    number: "",
    zip: "",
    city: "",
  });

  useEffect(() => {
    if (
      data &&
      data.createFacility &&
      Object.keys(userFacilitiesByID).includes(data.createFacility.id)
    ) {
      setLoading(false);
      navigate(`/employer/${data.createFacility.id}/facility-settings/edit`);
    }
  }, [userFacilitiesByID]);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    createFacility({
      variables: {
        name: formVars.name,
        address: {
          street: formVars.street,
          number: formVars.number,
          zip: formVars.zip,
          city: formVars.city,
        },
      },
    }).then(() => {
      getUserData();
    });
  };

  return (
    <>
      <JimHelmet path="create-facility" />
      <ContentWithoutUtilsBar>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-8 pb-2">
          <div>
            <TilePrim>
              <form id="create-facility-form" onSubmit={handleSubmit} tw="p-8">
                <div tw="flex items-center justify-between">Name</div>
                <TextField
                  id="facility-name"
                  value={formVars.name}
                  variant="outlined"
                  required
                  onChange={e =>
                    setFormVars({
                      ...formVars,
                      name: e.target.value,
                    })
                  }
                />
                <div tw="flex items-center justify-between mt-8">Adresse</div>
                <div tw="flex space-x-4 mb-2">
                  {["street", "number"].map(item => (
                    <TextFieldWithWidth
                      key={`facility-${item}`}
                      width={textFieldLib[item].width}
                      id={`facility-${item}`}
                      value={formVars[item]}
                      label={textFieldLib[item].label}
                      variant="outlined"
                      onChange={e =>
                        setFormVars({
                          ...formVars,
                          [item]: e.target.value,
                        })
                      }
                    />
                  ))}
                </div>
                <div tw="flex space-x-4">
                  {["zip", "city"].map(item => (
                    <TextFieldWithWidth
                      key={`facility-${item}`}
                      width={textFieldLib[item].width}
                      id={`facility-${item}`}
                      value={formVars[item]}
                      label={textFieldLib[item].label}
                      variant="outlined"
                      onChange={e =>
                        setFormVars({
                          ...formVars,
                          [item]: e.target.value,
                        })
                      }
                    />
                  ))}
                </div>
                <JimButton variant="primary" type="submit" tw="mt-8 self-start">
                  Einrichtung erstellen
                </JimButton>
              </form>
            </TilePrim>
          </div>
          <div tw="flex flex-col space-y-4 pt-4">
            <div tw="font-medium text-base">
              Mit einer Einrichtung kannst du...
            </div>
            <FeatureTile
              svg={<PoolsSvg />}
              title="Pools erstellen"
              descr="Arbeitnehmer werden bei uns in Pools organisiert. So werden
                  erstellte Jobs immer für die richtige Zielgruppe sichtbar."
            />
            <FeatureTile
              svg={<AvailabilitiesSvg />}
              title="Verfügbarkeiten finden"
              descr="Ihr Team meldet regelmäßig verfügbare Zeiten. So haben Sie immer im Blick, wer gerade zur Verfügung steht."
            />
            <FeatureTile
              svg={<FinishJobsSvg />}
              title="Zeiterfassung automatisieren"
              descr="Die Einsätze werden online beendet und die Arbeitszeit hierbei erfasst. Das schafft Überblick und vereinfacht die Abrechnung."
            />
            <div tw="bg-white shadow-md p-4 text-base rounded-md">
              Alle Vorteile und unsere Preisgestaltung findest du auch{" "}
              <a
                href="https://www.morrowmed.de/"
                target="_blank"
                rel="noreferrer"
                tw="text-prim-500 font-semibold"
              >
                hier
              </a>
              .
            </div>
          </div>
        </div>
        <LoadingDialog open={loading} />
        {error && <ErrorDialog />}
      </ContentWithoutUtilsBar>
    </>
  );
};

export default CreateFacility;
